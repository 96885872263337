import { useEffect } from 'react';

import { useLocalStorage, useMount } from 'react-use';

import { Button, IconButton, Link, Stack, Typography } from '@mui/material';

import isEmpty from 'lodash/isEmpty';

import * as Sentry from '@sentry/react';
import XIcon from '@/components/Icons/XIcon';
import { links } from '@/localization';
import { executeQuery } from '@datocms/cda-client';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';

import { latestChangelogState } from './atoms';
import { AlertContainer } from './styled';
import { Changelog, LatestChangelogResponse, UseLatestChangelog } from './types';

function useLatestChangelog(): UseLatestChangelog {
  const [latestChangelog, setLatestChangelog] = useRecoilState(latestChangelogState);
  const [seenChangelog, setSeenChangelog] = useLocalStorage<Record<string, boolean>>(
    'seen-changelog-ids',
    {},
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function markChangelogAsSeen(changelog?: Changelog[]): void {
    setSeenChangelog(
      (changelog || latestChangelog).reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),
    );
  }

  function getAlertChangelog(changelog: Changelog[], checkAll = false): Changelog | undefined {
    return changelog
      .filter(({ id }) => checkAll || !seenChangelog[id])
      .find(({ alertOnApp }) => alertOnApp);
  }

  useEffect(() => {
    if (!isEmpty(latestChangelog)) {
      setSeenChangelog((prevState) =>
        produce(prevState, (draft) => {
          latestChangelog.forEach(({ id }) => {
            draft[id] = draft[id] || false;
          });

          return draft;
        }),
      );
    }
  }, [latestChangelog, setSeenChangelog]);

  function closeAlert(changelog: Changelog[]): void {
    closeSnackbar();
    markChangelogAsSeen(changelog);
  }

  function handleChangelongClick(changelog: Changelog[], changelogId: string): void {
    const alertChangelog = getAlertChangelog(changelog, true);

    if (alertChangelog?.id === changelogId) {
      closeAlert(changelog);
    }
  }

  function showAlert(changelog: Changelog[]) {
    const alertChangelog = getAlertChangelog(changelog);

    if (alertChangelog && !window.Cypress) {
      enqueueSnackbar(alertChangelog.title, {
        persist: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (_, message) => (
          <AlertContainer>
            <Stack
              sx={{ pl: 1 }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">What&apos;s new ✨</Typography>
              <IconButton size="small" onClick={() => closeAlert(changelog)}>
                <XIcon />
              </IconButton>
            </Stack>
            <Button
              component={Link}
              variant="text"
              href={`${links.basetenChangeLog}/${alertChangelog.slug}`}
              onClick={() => closeAlert(changelog)}
              target="_blank"
              rel="noreferrer"
              sx={{ p: 1, fontWeight: 400 }}
            >
              {message}
            </Button>
          </AlertContainer>
        ),
      });
    }
  }

  useMount(async () => {
    if (isEmpty(latestChangelog)) {
      try {
        const result: LatestChangelogResponse = await executeQuery(
          '{ allChangelogs(first: 3, orderBy: _createdAt_DESC) { title, id, slug, alertOnApp } }',
          {
            token: ENV_CONFIG.DATOCMS_READONLY_KEY,
          },
        );

        setLatestChangelog(result.allChangelogs);
        showAlert(result.allChangelogs);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  });

  return {
    latestChangelog,
    seenChangelog,
    markChangelogAsSeen,
    onChangelogClick: handleChangelongClick,
  };
}

export { useLatestChangelog };
